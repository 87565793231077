import { Route, Routes, useLocation } from "react-router-dom";
import { onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { useCallback, useEffect } from "react";
import firebaseAuth from "./core/firebase.core";

import SignUp from "./view/Auth/signUp";
import SignIn from "./view/Auth/signIn";
import Verify from "./view/Auth/verify";
import Reset from "./view/Auth/reset";
import SetPassWord from "./view/Auth/reSetPassword";
import CheckEmail from "./view/Auth/checkEmail";
import HasBeenChange from "./view/Auth/hasBeenChange";
import Profile from "./view/Profile";
import Reservation from "./view/Reservation";

import CompleteOrder from "./view/CompleteOrder";
import { ModelTemplate } from "./models/modelTemplate";
import { SessionService } from "./services/session.service";

import { useDispatch } from "react-redux";
import { authenticate } from "./modules/authentication";
import ModelSelection from "./view/ModelSelection";
import ModelTemplatePage from "./view/ModelTemplate";
import LoopCounter from "./view/shared/components/LoopCounter";
import { fetchUserInfo } from "./modules/user/thunk";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";

import config from "./core/env.core";
import AllModelSelection from "./view/AllModelSelection";
import i18next from "i18next";
const tagManagerArgs = {
  gtmId: config.gtm_id
};

TagManager.initialize(tagManagerArgs);

const sessionService = new SessionService();

function App() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const auth = firebaseAuth;

  const location = useLocation();

  i18n.on("languageChanged", (lng) => {
    if (document.documentElement.lang === lng) return;
    document.documentElement.lang = lng;
  });

  useEffect(() => {
    // console.log("i18next: ", i18next.language);
    // i18next.changeLanguage("en");
    const lng = localStorage.getItem("i18nextLng");
    if (["en", "fr"].includes(lng)) return;
    i18next.changeLanguage("en");
  }, []);

  useEffect(() => {
    // if (config.node_env !== "prod") return;
    console.log("move");
    const tagManagerArgs = {
      dataLayer: {
        page: location
      },
      dataLayerName: "PageDataLayer"
    };
    TagManager.dataLayer(tagManagerArgs);
  }, [location]);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (!user) {
        await signInAnonymously(auth);
      }

      const tokenResult = await user.getIdTokenResult();

      sessionStorage.setItem("isAnonymous", user.isAnonymous);

      sessionService.saveToken(tokenResult.token);
      dispatch(
        authenticate({
          token: tokenResult.token,
          user: {
            uid: user.uid,
            email: user?.email,
            isAnonymous: user.isAnonymous
          }
        })
      );

      if (!user.isAnonymous) {
        dispatch(fetchUserInfo());
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const handleOnTicker = useCallback(async () => {
    const user = auth.currentUser;
    const tokenResult = await user.getIdTokenResult(true);

    sessionService.saveToken(tokenResult.token);
  }, [auth.currentUser]);

  return (
    <>
      <Routes>
        <Route path="/" index element={<ModelSelection />} />
        <Route path="/all" index element={<AllModelSelection />} />
        <Route
          path="model/:modelId"
          index
          element={<ModelTemplatePage templateType={ModelTemplate.reserved} />}
        />
        <Route path="reserve/complete/:orderId" element={<CompleteOrder />} />
        <Route
          path="model/share/:shareId"
          element={<ModelTemplatePage templateType={ModelTemplate.shared} />}
        />
        <Route
          path="model/order/:orderId"
          element={<ModelTemplatePage templateType={ModelTemplate.order} />}
        />
        <Route path="/sign-up" index element={<SignUp />} />
        {/* register */}
        <Route path="/sign-in" index element={<SignIn />} />
        {/* login */}
        <Route path="/sign-up/:id" element={<SignIn />} />
        <Route path="/verify" index element={<Verify />} />
        <Route path="/profile" index element={<Profile />} />
        <Route path="/check-email" index element={<CheckEmail />} />
        <Route path="/reset" index element={<Reset />} />
        <Route path="/forgot/:id" index element={<SetPassWord />} />
        <Route path="/reservation" element={<Reservation />} />
        <Route path="/has-been-changed" index element={<HasBeenChange />} />
      </Routes>
      <div className=" hidden">
        <LoopCounter onComplete={() => handleOnTicker()} />
      </div>
    </>
  );
}

export default App;
