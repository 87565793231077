import i18next, { t } from "i18next";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";

const ModelCard = ({ model, currency, isSelected, onSelectOption }) => {
  const navigate = useNavigate();
  const getIncludePart = (parts = []) => {
    const includePart = [...parts].sort((a, b) => a.order - b.order);
    return includePart;
  };

  return (
    <div
      className={`group rounded-lg w-full h-full flex flex-col items-center space-y-4 bg-white ${
        isSelected ? "bg-opacity-30" : "bg-opacity-0"
      } hover:bg-opacity-30 px-3 py-5`}>
      <div
        className={`h-[95px] ${
          isSelected ? "visible" : "invisible"
        } group-hover:visible w-full flex flex-col items-center space-y-3 `}>
        <p>{model.extendDemoPartInformation?.description?.name[i18next.language]}</p>
        <div className="flex gap-2 justify-center">
          <ul
            role="button"
            className="flex space-x-3 whitespace-nowrap md:whitespace-normal overflow-x-auto md:overflow-hidden pb-5">
            {getIncludePart(model?.extendDemoPartInformation?.parts).map((part, index) => (
              <li key={index.toString()} onClick={() => onSelectOption(part.image)}>
                <img src={part.image} className="object-cover border h-[60px] " />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <img src={model.image} className="w-auto  max-h-[250px] object-cover " />
      <div className="flex flex-col space-y-2 items-center">
        <p className="text-xl font-semibold">{model.name}</p>
        <p className=" inline-flex space-x-1 font-bold text-3xl">
          <span>{currency.prefix}</span>
          <NumericFormat
            value={model.price[currency?.dbKey] || 0}
            displayType={"text"}
            thousandSeparator={true}
          />
        </p>
      </div>
      <button
        onClick={() =>
          navigate({
            pathname: `/model/${model.modelId}`,
            search: "?start=personalize"
          })
        }
        className={`${
          isSelected ? "flex" : "hidden"
        } rounded bg-white   hover:bg-primary hover:text-white text-black group-hover:flex px-3 items-center justify-center w-[276px] h-[47px] md:h-[67px] text-base md:text-2xl font-medium`}>
        {t("Personalise")}
      </button>
      <button
        onClick={() =>
          navigate({
            pathname: `/model/${model.modelId}`
          })
        }
        className={`${
          isSelected ? "" : "hidden"
        } rounded bg-white border-2 border-primary hover:bg-primary hover:text-white  space-x-1 h-[47px] text-black md:h-[67px] px-3  group-hover:block items-center justify-center w-[276px] text-base md:text-2xl font-medium`}>
        <span>{t("Reserve Now")} </span>
        <span className={i18next.language === "fr" ? "block" : ""}>
          {currency?.prefix}&nbsp;
          <NumericFormat value={currency?.dbKey === "thb" ? 4000 : 100} displayType={"text"} thousandSeparator={true} />
        </span>
        {/* {`${t("Reserve Now")} ${currency?.prefix} 100`} */}
      </button>
      {isSelected && <p>{t("(Refundable)")}</p>}
    </div>
  );
};

export default ModelCard;
