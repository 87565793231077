import { t } from "i18next";
import ListBulletIcon from "../../Icons/ListBulletIcon";
import SlideHorizontalIcon from "../../Icons/SlideHorizontalIcon";

const SegmentItem = ({ icon, title, isActive, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`bg-white flex items-center gap-2 text-white px-2 py-1 text-xs md:text-xl sm:text-base rounded ${
        isActive ? "bg-opacity-30" : "bg-opacity-0"
      }`}>
      <span>{icon}</span>
      <span>{title}</span>
    </button>
  );
};

const PageSegment = ({ selectedItem = 0, onChange }) => {
  return (
    <div className=" border rounded flex p-1">
      <SegmentItem
        icon={<SlideHorizontalIcon className="w-5 h-5 md:w-6 md:h-6" />}
        title={t("Slide View")}
        isActive={selectedItem === 0}
        onClick={() => onChange(0)}
      />
      <SegmentItem
        icon={<ListBulletIcon className="w-5 h-5 md:w-6 md:h-6" />}
        title={t("List View")}
        isActive={selectedItem === 1}
        onClick={() => onChange(1)}
      />
    </div>
  );
};

export default PageSegment;
