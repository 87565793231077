import { t } from "i18next";
import { NumericFormat } from "react-number-format";
// import ChevronRight from "../../Icons/CheronRight";

const BannerModel = ({ currency = {}, onReserve }) => {
  return (
    <div className=" bg-white text-sm py-3 md:text-base  text-black px-5 shadow-xl">
      <div className="flex items-center justify-center ">
        <button
          onClick={onReserve}
          className=" font-semibold text-xl md:text-2xl inline-flex space-x-2">
          <span>{t("Reserve Now")}</span>
          <span>{currency?.prefix}</span>
          <span><NumericFormat value={currency?.dbKey === "thb" ? 4000 : 100} displayType={"text"} thousandSeparator={true} /></span>
        </button>
      </div>
    </div>
  );
};

export default BannerModel;
